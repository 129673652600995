import React from 'react';
import { Trans } from 'react-i18next';

import handsonface from '../../../assets/icons/hands-over-face.gif';

export default function HandsOnFace(props) {
  return (
    <div className="content-dont">
      <img src={handsonface} alt="handsonface"/>

      <div className="content-instructions">
        <p>
          <Trans i18nKey="Don't put your hands on your face. <br/> Also, ensure that your hair does not cover your eyebrows.">
             Don't put your hands on your face. <br/> Also, ensure that your hair does not cover your eyebrows.
          </Trans>
        </p>
      </div>
    </div>
  );
}
