import React, { useState, useEffect } from 'react';
import { g } from '../../helpers/functions.js';
let imgDurationTimeout = null;

export default function Stimulus({ stimuli, taskName, nextTask, showVideo, startRecording, stopRecording }) {
  let hideElement = el => {
    if (!el) return;
    el.style.display = 'none';
  };

  let showElement = el => {
    el.style.display = 'block';
  };

  let onProceed = () => {
    if (stimuli.type === 'image') {
      window.clearTimeout(imgDurationTimeout);
      //  window.timeStats.updateStimulus(taskName, stimuli.name, 'stoppedRecording');
      let stimulusEl = document.getElementById('stimulusVideo');
      stimulusEl.style.display = 'none';
    } else {
      let stimulusEl = document.getElementById('stimulusImg');
      stimulusEl.style.display = 'none';
    }
    stopRecording();
  }

  let startRecordingImage = image => {
    startRecording();
    window.timeStats.updateStimulus(taskName, stimuli.images[stimuli.index].name, 'startedRecording')
    imgDurationTimeout = window.setTimeout(onProceed, image.duration);
  }

  let showImages = () => {
    if (!stimuli.images[stimuli.index]) return;
    let taskIntroEl = document.getElementsByClassName('task-intro')[0];
    let stimulusEl = document.getElementById('stimulusImg');
    let stimulusElVideo = document.getElementById('stimulusVideo');
    hideElement(taskIntroEl);
    hideElement(stimulusElVideo);
    stimulusEl.onload = () => {
      startRecordingImage(stimuli.images[stimuli.index]);
    }
    stimulusEl.src = stimuli.images[stimuli.index].path;
    showElement(stimulusEl);
    //    g.updateTest('stimulus', (stimuli.index + 1));
  };

  let showVideos = () => {
    let taskIntroEl = document.getElementsByClassName('task-intro')[0];
    let stimulusEl = document.getElementById('stimulusVideo');
    hideElement(taskIntroEl);
    showElement(stimulusEl);
  };

  useEffect(() => {
    if (!stimuli.type || stimuli.index === -1) return;
    if (stimuli.type === 'image') {
      showImages();
      return;
    }
    if (stimuli.type === 'video') return;
  }, [stimuli.type, stimuli.index]);


  return (
    <div className="stimulus">
      <>
        <StimulusExit canExit={stimuli.exitEnabled} proceed={onProceed} />
        <StimulusImage />
        <StimulusVideo stopRecording={stopRecording} />
      </>
    </div>
  )
};

function StimulusExit({ canExit, proceed }) {
  return (
    canExit ? <button className="btn-floating btn-eyesee btn-large" onClick={proceed}> Proceed </button> : null
  )
};

function StimulusImage() {
  useEffect(() => {
    if (window.innerWidth < 600) {
      document.getElementById('stimulusImg').style.height = '90vw';
    }
  }, []);

  return (
    <div className="stimulus-img-wrapper">
      <img src='' id="stimulusImg" alt=""></img>
    </div>
  )
}

function StimulusVideo({ stopRecording }) {
  useEffect(() => {
    if (window.innerWidth < 600) {
      document.getElementById('stimulusVideo').style.width = '100vw';
    }
  }, []);

  let onVideoEnded = () => {
    stopRecording();
  };

  let preventContextMenu = e => {
    e.preventDefault();
    return false;
  };

  return (
    <video src='' id="stimulusVideo" onEnded={onVideoEnded} onContextMenu={preventContextMenu}></video>
  )
}
