import introduction from '../assets/icons/introduction.png';
import questions from '../assets/icons/questions.png';
import calibration from '../assets/icons/calibration.png';
import media from '../assets/icons/media.png';
import uploading from '../assets/icons/uploading.png';
import finish from '../assets/icons/finish.png';

export const steps = {
  init: () => {
    let steps = [{
        title: 'Introduction',
        status: 'next',
        icon: introduction
      },
      {
        title: 'Setup',
        status: 'next',
        icon: questions
      },
      {
        title: 'Calibration',
        status: 'next',
        icon: calibration
      },
      {
        title: 'Tasks',
        status: 'next',
        icon: media
      },
      {
        title: 'Uploading',
        status: 'next',
        icon: uploading
      },
      {
        title: 'Finish',
        status: 'next',
        icon: finish
      }
    ];
    // let w = document.documentElement.clientWidth;
    // let h = document.documentElement.clientHeight;
    //
    // if (w < 992 && h >= 280 && h <= 576) {
    //   return steps;
    // }

    return steps;
  }
}
