import React from 'react';
import { useTranslation } from 'react-i18next';

export default function Error (props) {
  const {t} = useTranslation();
  let tryAgainStyle = props.hideTryAgain ? {display: 'none'} : {display: 'inline'};

  return (
    <div className="restart card-panel">
      <h5>
        <i className="material-icons">warning</i>
        <span> {t("Error")} </span>
      </h5>

     {props.children}

      <div className="controls">
        <button className="btn btn-outlined" onClick={props.cancel}> {t("Cancel")} </button>
        <button className="btn btn-eyesee-linear" onClick={props.onStartOver} style={tryAgainStyle}> {t("Try again")} </button>
      </div>
    </div>
  )
}
