import React from 'react';
import lightBulb from '../../../assets/icons/light-bulb.png';
import clickTracking from '../../../assets/icons/click-tracking.png';
import chair from '../../../assets/icons/chair.png';
import { useTranslation } from 'react-i18next';

export default function Do(props) {
  const {t} = useTranslation();

  return (
          <>
            <div className="content-item">
              <img src={lightBulb} alt="light bulb"/>
              <p> { t("Do the interview in a well lit room.") } </p>
            </div>

            <div className="content-item">
              <img src={clickTracking} alt=""/>
              <p> { t("If possible, connect and use an external mouse.") } </p>
            </div>

            <div className="content-item">
              <img src={chair} alt="chair"/>
              <p> { t("Don't forget to sit comfortably and support your back!") }</p>
            </div>
          </>
        )
}
