import React from 'react';
import { useTranslation } from 'react-i18next';

function Stepper(props) {
  const {t} = useTranslation();

  let setStepStatus = i => {
    if (i === props.activeStep) {
      props.steps[i].status = 'current';
    }

    if (i > props.activeStep) {
      props.steps[i].status = 'next';
    }

    if (i < props.activeStep) {
      props.steps[i].status = 'completed';
    }
  }

  let stepList = props.steps.map((step, i) => {
    setStepStatus(i);
    let classes = 'step ' + step.status + (i === props.steps.length - 1 ? ' last-step': '');
    return (
            <div className={classes} key={i}>
             <div className="indicator">
               <span className="icon-mask"></span>
               <img src={step.icon} alt=""/>
               <p className="title">{i + 1}. {t(step.title)}</p>
             </div>
           </div>
          )
  });

  return (
    <div className="stepper">
      {stepList}
    </div>
  )

}

export default Stepper;
