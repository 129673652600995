export const stimulusVideo = {
  init: function (startRecording, onBufferStarts, onBufferEnds) {
    this.taskIntroEl = document.getElementsByClassName('task-intro')[0];
    this.spinnerEl = document.getElementsByClassName('spinner')[0];
    this.videoEl = document.getElementById('stimulusVideo');
    this.startRecording = startRecording;
    this.onBufferStarts = onBufferStarts;
    this.onBufferEnds = onBufferEnds;
    this.isRecording = false;
  },

  setCurrentData: function (taskName, stimulusName) {
    this.taskName = taskName;
    this.stimulusName = stimulusName;
  },

  play: function () {
    let self = this;
    this.videoEl.play();
    window.timeStats.updateStimulus(this.taskName, this.stimulusName, 'play');
    this.timeUpdateInt = window.setInterval(() => this.checkTimeUpdate(this.videoEl), 50);
    this.videoEl.onplaying = () => self.onPlaying.call(self);
    this.videoEl.onwaiting = () => self.onWaiting.call(self);
    // this.videoEl.addEventListener('timeupdate', e => self.onStartRecording(e));
  },

  checkTimeUpdate: function (video) {
    if (video.currentTime <= 0 || this.isRecording) return;
    window.clearInterval(this.timeUpdateInt);
    window.timeStats.updateStimulus(this.taskName, this.stimulusName, 'playCallback');
    this.startRecording();
    this.isRecording = true;
  },

  onStartRecording: function (event) {
    if (event.currentTarget.currentTime <= 0 || this.isRecording) return;
    event.currentTarget.removeEventListener('timeupdate', this.onStartRecording);
    window.timeStats.updateStimulus(this.taskName, this.stimulusName, 'startedRecording');
    this.startRecording();
    this.isRecording = true;
  },

  onPlaying: function () {
    window.timeStats.updateStimulus(this.taskName, this.stimulusName, 'playing');
    this.onBufferEnds();
  //  window.timeStats.updateStimulus(this.taskName, this.stimulusName, 'bufferEnded');
  },

  onWaiting: function () {
    window.timeStats.updateStimulus(this.taskName, this.stimulusName, 'waiting');
    this.onBufferStarts();
  //  window.timeStats.updateStimulus(this.taskName, this.stimulusName, 'bufferStarted');
  },

  onVideoSrcAvailable: function (videoSrc) {
    let self = this;
    this.videoEl.src = videoSrc;
    this.taskIntroEl.style.display = 'none';
    this.spinnerEl.style.display = 'inline';

    this.videoEl.oncanplaythrough = () => {
      this.spinnerEl.style.display = 'none';
      this.videoEl.style.visibility = 'visible';
      this.videoEl.style.display = 'block';
      self.videoEl.oncanplaythrough = null;
      self.play();
    }
  },

  checkIsVideoDownloaded(task, idx) {
    let isLoading = false;
    let isStimulusDownloadedInt = window.setInterval(() => {
      let videoSrc = task.videoBlobs[idx];
      if (videoSrc) {
        window.timeStats.updateStimulus(this.taskName, this.stimulusName, 'intervalOK');
        window.clearInterval(isStimulusDownloadedInt);
        this.onVideoSrcAvailable(videoSrc);
      } else {
        if (!isLoading) {
          this.taskIntroEl.style.display = 'none';
          this.spinnerEl.style.display = 'inline';
        }
      }
    }, 500);
  }
}
