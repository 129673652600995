import React from 'react';
import { useTranslation } from 'react-i18next';

export default function Instruction(props) {
  let {title, text, isOK, additionalClasses} = props;
  let icon = isOK ? <i className="material-icons green-text text-lighten-2">check_circle</i> : <i className="material-icons red-text text-accent-1">cancel</i>;
  const {t} = useTranslation();

  return (
    <li className={"card-panel grey darken-3 instruction " + additionalClasses}>
      <section className="instruction-img">
        {icon}
      </section>

      <section className='instruction-text'>
        <h6>{t(title)}</h6>
        <p>{t(text)}</p>
      </section>
    </li>
  );
}
