export const parameters = {
  saveURLParams: () => {
    let parameters = {};
    let urlParams = new URLSearchParams(window.location.search);

    for(let pair of  urlParams.entries()) {
      if (pair[0] === 'uUserID' || pair[0] === 'tester_id') {
        pair[1] = parseInt(pair[1]);
      }
      if (pair[0] === 'fcOnly' && pair[1] === 'true') {
        window.isFCOnly  = true;
      }
      parameters[pair[0]] = pair[1];
    }
    window.parameters = parameters;
  }
}
