import React from 'react';
import { useTranslation } from 'react-i18next';

export default function Finish(props) {
  const { t } = useTranslation();

  let redirectTimeout = setTimeout(() => {
    redirect();
  }, 10000);

  let redirect = () => {
    clearTimeout(redirectTimeout);
    window.location.href = `${process.env.REACT_APP_API_URL}/front/endTest?tester_id=${window.parameters.tester_id}`;
  }

  return (
    <div className="tutorial finish">
      <div className="card-panel">
        <h5> {t("Thank you")} </h5>
        <p> {t("You have passed all the steps and we really appreciate your work.")} </p>
        <p> {t("You will be redirected back to the survey page in a few moments.")} </p>
        <div>
          <button className="btn-large btn-floating btn-eyesee" onClick={redirect}> {t("Redirect now")} </button>
        </div>
      </div>
    </div>
  )
}
