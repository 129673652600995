import {g} from '../helpers/functions.js';
import axios from 'axios';

export const tasks = {
  init (project) {
    let tasks = project.tasks;
    if (project.tasksRandomize)
      tasks = g.randomizeArr(tasks);
    window.tasks = tasks;
    return tasks;
  },

  prepareStimuli() {
    for (let i = 0; i < window.tasks.length; i++) {
      let task = window.tasks[i];
      if (task.imgs.length) {
        task.imgs = task.imgsRandom ? g.randomizeArr(task.imgs) : task.imgs;
        continue;
      };
      task.videos = task.videosRandom ? g.randomizeArr(task.videos) : task.videos;
      task.videoBlobs = [];
      if (task.isPreloadVideos)
        this.fetchVideos(task, i, 0);
    }
  },

  fetchVideos(task, taskIndex, videoIndex) {
    window.timeStats.updateStimulus(task.name, task.videos[videoIndex].name, 'downloadStarted');
    axios({
      method: 'get',
      url: task.videos[videoIndex].path,
      responseType: 'blob'
    }).then(response => {
      window.timeStats.updateStimulus(task.name, task.videos[videoIndex].name, 'downloadEnded');
      var videoBlob = response.data;
      task.videoBlobs[videoIndex] = URL.createObjectURL(videoBlob);
      videoIndex++;

      if (videoIndex === task.videos.length) return;
      this.fetchVideos(task, taskIndex, videoIndex);
    });
  }
}
