import {g} from '../helpers/functions.js';

export class Instructions {
  constructor() {
    this.list = [
      {id: 'sizeAndConditions', title: 'Face size and position', text: 'face inside the ellipse', isOK: false, additionalClasses: "positioned-left"},
      {id: 'FaceIlluminationOk', title: 'Face illumination', text: 'adjust lighting so your face is not too bright nor too dark', isOK: true, additionalClasses: "positioned-left instruction-2"},
      {id: 'BacklightOk', title: 'Backlight', text: 'avoid light sources directly behind you', isOK: false, additionalClasses: "positioned-right"},
      {id: 'AmbientLightingOk', title: 'Ambient lighting', text: 'adjust room lighting', isOK: false, additionalClasses: "positioned-right instruction-2"},
      {id: 'VideoQualityOk', title: 'Video quality', text: 'change the web camera if the video is noisy', isOK: false, additionalClasses: "positioned-right instruction-3"}
    ]
  }

  setCondition = (key, isOK) => {
    this.list.forEach(instruction => {
      if (instruction.id === key)
        instruction.isOK = isOK;
    });
    return this.list;
  }

  setAllConditions = (conditions) => {
    for (let i = 0; i < this.list.length; i++) {
      // face size and position are separated on API
      if (i === 0) {
        this.list[i].isOK = !!(conditions['FaceSizeOK'] && conditions['FacePositionOk']);
        continue;
      }
      if (conditions[this.list[i].id]) {
        this.list[i].isOK = !!(conditions[this.list[i].id]);
      }
    }
    return this.list;
  }

  reset = () => {
    return this.list.map(instruction => {
      instruction.isOK = false;
      return instruction;
    });
  }

  getAll = () => {
    return g.deepCopy(this.list);
  }
}


export const conditionsPassed = {
  "BacklightOk": 1,
  "AmbientLightingOk": 1,
  "VideoQualityOk": 1,
  "FaceIlluminationOk": 1,
  "FaceSizeOK": 1,
  "FacePositionOk": 1
}

export const instructionsExample2 = {
  "BacklightOk": 1,
  "AmbientLightingOk": 0,
  "VideoQualityOk": 0,
  "FaceIlluminationOk": 0,
  "FaceSizeOK": 1,
  "FacePositionOk": 1
}
