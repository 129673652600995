import React from 'react';
import { useTranslation } from 'react-i18next';

export const updateTimeToProceed = timeToProceed => {
  let timeToProceedEl = document.getElementById('timeToProceed');
  timeToProceedEl.innerText = timeToProceed;
}

export const disableProceed = () => {
  let statusWait = document.getElementById('statusWait');
  let statusProceed = document.getElementById('statusProceed');
  statusWait.style.display = 'flex';
  statusProceed.style.display = 'none';
}

export const enableProceed = () => {
  let statusWait = document.getElementById('statusWait');
  let statusProceed = document.getElementById('statusProceed');
  statusWait.style.display = 'none';
  statusProceed.style.display = 'flex';
}

export const statusWaitHideCounter = () => {
  let counter = document.getElementById('statusWait-msg');
  let process = document.getElementById('statusWait-process');
    if (counter)
  counter.style.display = 'none';
    if (process)
  process.style.display = 'block';
}

export const statusWaitShowCounter = () => {
  let counter = document.getElementById('statusWait-msg');
  let process = document.getElementById('statusWait-process');
    if (counter)
  process.style.display = 'none';
    if (process)
  counter.style.display = 'block';
}

export default function Status(props) {
  const {t} = useTranslation();

  let cancel = () => {
    window.location.href = `${process.env.REACT_APP_API_URL}/front/cancelTest?tester_id=${window.parameters.tester_id}`;
  }

  return (
    <div className="camera-status card-panel">
        <section id="statusProceed">
          <p>{ t("Camera is ready.") }</p>
          <button className="btn btn-small btn-eyesee-linear" onClick={props.startCalibration}> { t("Continue") } </button>
        </section>

        <section id="statusWait">
          <p id="statusWait-process"> { t('Processing conditions, please wait...') } </p>
          <p id="statusWait-msg"> { t("Please stay still for about") } <span id="timeToProceed">3</span> { t("sec") } </p>
          <button className="btn btn-outlined btn-small" onClick={cancel}>{ t("Cancel") } </button>
        </section>
    </div>
  )
}
