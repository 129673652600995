const fullscreen = {
  on: element => {
    if (!element) return;

    if(element.requestFullscreen)
  		element.requestFullscreen();
  	else if(element.mozRequestFullScreen)
  		element.mozRequestFullScreen();
  	else if(element.webkitRequestFullscreen)
  		element.webkitRequestFullscreen();
  	else if(element.msRequestFullscreen)
  		element.msRequestFullscreen();
    window.isFullscreen = true;
  },

  off: () => {
    console.log('fs off', document.fullscreenElement)
    if(document.fullscreenElement)
  		document.exitFullscreen();
  	else if(document.mozFullScreenElement)
  		document.mozCancelFullScreen();
  	else if(document.webkitFullscreenElement)
  		document.webkitCancelFullScreen();
  	else if(document.msFullscreenElement)
  		document.msExitFullscreen();
    window.isFullscreen = false;
  },

  onChange: callback => {
    document.onfullscreenchange = callback;
  }
}

export default fullscreen
