import React, {useState} from 'react';
import Do from './setup/Do';
import Glasses from './setup/Glasses';
import HandsOnFace from './setup/HandsOnFace';
import Lightsource from './setup/Lightsource';
import { useTranslation } from 'react-i18next';
import {g} from '../../helpers/functions.js';
export default function Setup(props) {
  const {t} = useTranslation();

  let views = [
    <Do />,
    <Lightsource />,
    <Glasses />,
    <HandsOnFace />
  ];

  let [currentView, setCurrentView] = useState(0);

  let next = () => {
    g.updateTest('setup', currentView+1);
    if (currentView === views.length - 1) {
      props.next();
      return;
    }
    setCurrentView(++currentView);
  }

  return (
          <div className="guide">
            <div className="title">
              <h5> { t("Proper setup") } </h5>
            </div>

            <div className="content">
              {views[currentView]}
            </div>

            <div className="ctas">
              <button className="btn-floating btn-large btn-eyesee-outlined" onClick={props.cancel}> {t("Cancel")} </button>
              <button className="btn-floating btn-large btn-eyesee" onClick={next}> {t("Next")} </button>
            </div>
          </div>
        )
}
