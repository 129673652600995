import React, {useEffect} from 'react';
import api from '../api';
import { useTranslation } from 'react-i18next';
import {g} from '../helpers/functions.js';

export default function Uploading(props) {
  const {t} = useTranslation();

  useEffect(() => {
    let checkProgressInt = setInterval(async() => {
      if (window.stimuliUploadedCounter === window.totalStimuli /*|| (window.parameters.fcOnly && window.parameters.fcOnly === true)*/) {
        await clearInterval(checkProgressInt);
        g.updateTest('finish', 1);
        stopTest();
      }
    }, 1000)
  }, []);

  let stopTest = () => {
    let formData = new FormData();
    formData.append('uUserID', window.parameters.uUserID);

    api.stopTest(formData)
      .then(response => {
        console.log('stop test', response);
      })
      .catch(err => {
        console.log('error stopTest ', err);
      })
      .finally(() => {
        try {
          props.next();
        } catch (err) {

        }
      });
  }

  let view = (
    <>
      <div className="uploading-title">
        <i className="material-icons animated">settings</i>
        <p> { t("Please wait, uploading videos...") } </p>
      </div>

      <div className="uploading-progress">
        <div className="progress blue lighten-5">
          <div className="indeterminate blue"></div>
        </div>
       </div>
    </>
  )

  return (
    <div className="tutorial uploading">
      {view}
    </div>
  )
}
