import React from 'react';
import Instruction from './Instruction.jsx';

export default function Instructions ({instructions}) {
  return (
    <ul className="instructions">
      {
        instructions.map((instruction, i) => {
          return (
            <Instruction title={instruction.title}
                         text={instruction.text}
                         isOK={instruction.isOK}
                         additionalClasses={instruction.additionalClasses}
                         key={i}/>
          )
        })
      }
    </ul>
  )
}
