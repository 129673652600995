import React, {Component} from 'react';
import Conditions from './conditions/Conditions.jsx';
import Task from './tasks/Task.jsx';
import {g} from '../helpers/functions.js';

export default class Fullscreen extends Component {
  constructor (props) {
    super(props);
    this.state = {
      activeView: (window.parameters.skipCalibration && window.parameters.skipCalibration === 'true') ? 1 : 0
    }
  }

  goToNextView = () => {
    this.setState({activeView: this.state.activeView + 1});
  }

  recalibrate = () => {}

  goToNextTask = () => {
    if (this.tasks[this.state.activeTaskIdx + 1].recalibrate) {
      this.recalibrate();
      return;
    }
  }

  render () {
    let view = this.state.activeView === 0 ? <Conditions goToNextView={this.goToNextView} />
                                           :
                                            <Task exitFullscreen={this.props.exitFullscreen}/>;

    return (
      <div className="fullscreen">
        {view}
      </div>
    )
  }
}
