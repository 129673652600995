// let dotWidth = 50;
// let dotHeight = 50;
let initialPositionLeft = 40;
let initialPositionTop = 40;

let animTimeShort = '1.5s';
let animTimeLong = '2.2s';

let xMiddle = (window.screen.width - (initialPositionLeft)) / 2;
let yMiddle = (window.screen.height - (initialPositionTop)) / 2;

let paths = [
  {left: initialPositionLeft, top: initialPositionTop, animationDuration: animTimeShort},
  {left: xMiddle / 2 , top: initialPositionTop, animationDuration: animTimeShort},
  {left: xMiddle + xMiddle / 2, top: initialPositionTop, animationDuration: animTimeLong},
  {left: window.screen.width - initialPositionLeft, top: initialPositionTop, animationDuration: animTimeShort},

  {left: window.screen.width - initialPositionLeft, top: yMiddle / 2, animationDuration: animTimeShort},
  {left: window.screen.width - initialPositionLeft, top: yMiddle + yMiddle / 2, animationDuration: animTimeLong},
  {left: window.screen.width - initialPositionLeft, top: window.screen.height - initialPositionTop, animationDuration: animTimeShort},

  {left: window.screen.width - xMiddle/2, top: window.screen.height - initialPositionTop, animationDuration: animTimeShort},
  {left: xMiddle - xMiddle / 2, top: window.screen.height - initialPositionTop, animationDuration: animTimeLong},
  {left: initialPositionLeft, top: window.screen.height - initialPositionTop, animationDuration: animTimeShort},

  {left: initialPositionLeft, top: window.screen.height - yMiddle/2, animationDuration: animTimeShort},
  {left: initialPositionLeft, top: yMiddle - yMiddle / 2, animationDuration: animTimeLong},

  {left: initialPositionLeft + xMiddle/2, top: yMiddle - yMiddle / 2, animationDuration: animTimeShort},
  {left: xMiddle + xMiddle/2, top: yMiddle - yMiddle / 2, animationDuration: animTimeLong},

  {left: xMiddle + xMiddle/2, top: yMiddle + yMiddle / 2, animationDuration: animTimeLong},
  {left: initialPositionLeft + xMiddle/2, top: yMiddle + yMiddle / 2, animationDuration: animTimeLong},

  {left: xMiddle, top: yMiddle, animationDuration: animTimeShort}
];

export default paths;
