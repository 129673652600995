import React, {Component} from 'react';
import { useTranslation } from 'react-i18next';
import {Ctracker} from './Ctracker.js';
import Player from '../../entities/Player.js';
import InstructionsList from './Instructions.jsx';
import {Instructions} from '../../entities/Instructions.js';
import {conditionsPassed} from '../../entities/Instructions.js';
import api from '../../api/';
import {videoJSOptions, calcDimensions, setVideoJSDimensions} from '../../data/videoJSOptions.js';
import Status, {statusWaitShowCounter, statusWaitHideCounter, enableProceed, updateTimeToProceed, disableProceed} from './Status.jsx';
import StatusMobile from './StatusMobile.jsx';
import 'videojs-record/dist/plugins/videojs.record.ts-ebml.js';
import {g} from '../../helpers/functions.js';
import i18n from "i18next";
import fullscreen from '../../helpers/fullscreen.js';
import Error from '../Error.jsx';
let instructionsList = new Instructions();
let checkPositionCounter = 0;

export const resetCheckPositionCounter = () => {
  checkPositionCounter = 0;
}

export const stopAnimation = () => {
  let animationEl = document.getElementById('animation');
  if (animationEl)
    animationEl.style.display = 'none';
}

export default class CameraCheck extends Component {
  player;
  drawLoopInt;
  checkPositionInt;

  checkConditionsImg;
  areConditionsValidated;

  constructor(props) {
    super(props);
    checkPositionCounter = 0;
    this.state = {
      instructions: instructionsList.getAll(),
      isCameraEnabled: false,
      isCameraBlocked: -1,
      isMobileDevice: true
    }
  }

  requestAnimationFrame = () => {
    window.requestAnimFrame = (function() {
      return window.requestAnimationFrame ||
             window.webkitRequestAnimationFrame ||
             window.mozRequestAnimationFrame ||
             window.oRequestAnimationFrame ||
             window.msRequestAnimationFrame ||
             function(/* function FrameRequestCallback */ callback, /* DOMElement Element */ element) {
               return window.setTimeout(callback, 1000/60);
             };
    })();
  }

  onDeviceReady = () => {
    this.setState({isCameraEnabled: true, isCameraBlocked: false});
    window.isCameraEnabled = true;
    fullscreen.on(document.getElementsByClassName('App')[0]);
    if (!window.isMobileDevice) {
      this.ctracker.start();
      window.drawLoopInt = window.setInterval(this.ctracker.drawLoop, 1000);
      this.checkPositionInt = window.setInterval(this.checkPosition, 100);
    }
  }

  // uploadFCMedia = () => {
  //   eyeseeApi.uploadMedia();
  // }

  checkPosition = () => {
    if (checkPositionCounter === 0) {
      stopAnimation();
      disableProceed();
      updateTimeToProceed(3);
      this.areConditionsValidated = false;
      this.checkConditionsImg = null;
      let instructions = instructionsList.reset();
      this.setState({instructions});
    }

    checkPositionCounter += 100;

    if (checkPositionCounter >= 250) {
      this.startAnimation();
    }

    if (checkPositionCounter >= 3000) {
      if (window.parameters.fcOnly && window.parameters.fcOnly === true && !this.areConditionsValidated) {
        //  this.capture(this.uploadFCMedia);
          // eyeseeApi.uploadMedia();
          this.onConditionsCheck(conditionsPassed);
          enableProceed();
          if (!this.areConditionsValidated) {
            g.updateTest('positioned', 1);
          }
          this.areConditionsValidated = true;
          return;
       }
      if (!this.areConditionsValidated) {
        statusWaitHideCounter();
        this.capture(this.checkConditions);
        this.areConditionsValidated = true;
        g.updateTest('positioned', 1);
      }
    }

    if (checkPositionCounter % 500 === 0) {
      updateTimeToProceed(3 - checkPositionCounter / 1000);
    }
  }

  startAnimation = () => {
    let animationEl = document.getElementById('animation');
    if (animationEl)
      animationEl.style.display = 'block';
  }

  capture = (callback = null) => {
    const video = document.querySelector('video');
    const canvas = document.getElementById('videoimage-canvas');
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height);
    let file;
    canvas.toBlob(img => {
        file = new File([img], 'check.jpg');
        this.checkConditionsImg = file;
        if (callback)
          callback();
    }, 'image/jpeg');
  }

  checkConditions = () => {
    let formData = new FormData();
    let uUserID = window.parameters.uUserID;

    formData.append('uUserID', uUserID);
    formData.append('webCamVideoFrame', this.checkConditionsImg);

    api.checkConditions(formData)
      .then(response => {
        this.onConditionsCheck(response.data);
      })
      .catch(err => {
        if (err.response)
         this.onConditionsCheck(err.response);
      })
      .finally(() => {
        g.updateTest('conditions', 1);
      });
  }

  onConditionsCheck = (checkedConditions) => {
    let instructions = [];
    instructions = instructionsList.setAllConditions(checkedConditions);
    if (this.canCalibrate(instructions)) {
      enableProceed();
    } else {
      this.areConditionsValidated = false;
      checkPositionCounter=0;
      statusWaitShowCounter();
    }
    this.setState({instructions});
  }

  canCalibrate = instructions => {
    for (let i = 0; i < instructions.length; i++) {
      if (!instructions[i].isOK) return false;
    }
    return true;
  }

  disposePlayer = () => {
    setTimeout(()=> {
      this.player.destroy();
      this.props.startCalibration();
    }, 1);
  }

  proceed = async () => {
    window.preventLoop = true;
    if (!window.isMobileDevice)
      this.ctracker.stop();
    await clearInterval(window.drawLoopInt);
    await clearInterval(this.checkPositionInt);
    if (window.parameters.fcOnly && window.parameters.fcOnly === true) {
      this.props.goToNextView();
      return;
    }
    this.player.stop();
  }

  setOptions = (width, height, videoJSOptions) => {
    let options = JSON.parse(JSON.stringify(videoJSOptions));
    options.width = width;
    options.height = height;
    // options.plugins.record.video.width = width;
    // options.plugins.record.video.height = height;
    options.plugins.record.frameWidth = width;
    options.plugins.record.frameHeight = height;
    return options;
  }

  adjustDimensions = (width, height) => {
    let elems = [document.getElementById('animation'), document.getElementById('videoimage-canvas'), document.getElementById('overlay')];
    elems.forEach(el => {
      if (el) {
        el.style.width = width;
        el.style.height = height;
      }
    });

    var canvas = elems[2];
    canvas.width = width;
    canvas.height = height

    document.getElementById('animation').style.width = width + 'px';
    document.getElementById('animation').style.height = height + 'px';
  }

  onCameraBlocked = () => {
    this.setState({isCameraBlocked: 1});
    g.updateTest('camera_blocked', 1);
  }

  drawCircle = (width, height) => {
    let ctx = document.getElementById("overlay").getContext('2d');
    let cx = width / 2;
    let cy = height / 2;
    let r = cy / 1.2;
    ctx.strokeStyle = "#82ff32";
    ctx.lineWidth = 5;
    ctx.arc(cx, cy, r, 0, 2 * Math.PI);
    ctx.stroke();
  }

  render () {
    let pageVisibilityStyle = this.state.isCameraEnabled ? {display: 'block'} : {display: 'none'};
    let errorVisibilityStyle = (this.state.isCameraBlocked === 1 ||  this.state.noCamera === 1) ? {display: 'block'} : {display: 'none'};

    return (
      <>
        <div style={errorVisibilityStyle}>
          <Error hideTryAgain={true} cancel={() => window.location.href = `${process.env.REACT_APP_API_URL}/front/cancelTest?tester_id=${window.parameters.tester_id}`}>
            <p> {i18n.t("To continue the test, the camera has to be enabled. Please grant access and start over.")} </p>
          </Error>
        </div>


        <div className="video-check" style={pageVisibilityStyle}>
          <div className="title center-align">
            <h5> { i18n.t("Camera check") } </h5>
          </div>

          <div id="videoimage">
            <canvas id="videoimage-canvas" width="640" height="480"></canvas>
          </div>

          <div data-vjs-player className="rel">
            <div className="video-wrapper" id="animation"></div>
            <video muted="muted" id="recordingVideo" className="video-js vjs-default-skin" width="640" height="480"></video>
            <canvas id="overlay" width="640" height="480"></canvas>
          </div>

          {
            this.state.isMobileDevice ?
            <StatusMobile startCalibration={this.proceed}/>
              :
            (
              <>
                <Status startCalibration={this.proceed}/>
                <div className="information center-align">
                  <p> { i18n.t("All conditions have to be green to continue!")}</p>
                  <p> { i18n.t("Once you set them properly, please do not change lighting and try to keep still.")} </p>
                </div>

                <InstructionsList instructions={this.state.instructions} />
              </>
            )
          }
        </div>
      </>

    )
  }

  componentWillMount() {
    if (!window.isMobileDevice)
      this.ctracker = new Ctracker();
  }

  detectWebcam = (callback) => {
  let md = navigator.mediaDevices;
  if (!md || !md.enumerateDevices) return callback(false);
  md.enumerateDevices().then(devices => {
    callback(devices.some(device => 'videoinput' === device.kind));
  })
};

  componentDidMount() {
    this.setState({isMobileDevice: window.isMobileDevice});

    let callbacks = {
      onDeviceReady: this.onDeviceReady,
      onFinishRecord: this.disposePlayer,
      onCameraBlocked: this.onCameraBlocked
    }
    if (!window.isMobileDevice) {
      this.requestAnimationFrame();
    }

    let {width, height} = calcDimensions();
    let options = setVideoJSDimensions(width, height, videoJSOptions);
    this.adjustDimensions(width, height);
    this.detectWebcam((hasWebcam) => {
      if (!hasWebcam) {
        g.updateTest('no_camera', 1);
        this.setState({noCamera: 1});
        return;
      }
      // options.plugins.record.audio = window.isRecordingAudio;
      this.player = new Player(document.getElementById('recordingVideo'), options, callbacks);
      if (!window.isMobileDevice)
        this.ctracker.setElements('recordingVideo', 'overlay');
      else {
          document.getElementById("animation").classList.add("mobile");
          this.drawCircle(width, height);
      }
    });
  }

  componentWillUnmount() {
    this.player.destroy();
  }
}
