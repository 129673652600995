import {resetCheckPositionCounter, stopAnimation} from './CameraCheck.jsx';
import {calcDimensions} from '../../data/videoJSOptions.js';

export class Ctracker {
  constructor() {
    let clm = window.clm;
    this.instance = new clm.tracker();
    this.instance.init();
    window.preventLoop = false;
  }

  start = () => {
    this.adjustDimensions(this.videoElement);
    this.instance.start(this.videoElement);
  }

  stop = () => {
    this.instance.stop();
  }

  setElements = (videoID, ctrackID) => {
    this.videoElement = document.getElementById(videoID + '_html5_api');
    this.ctrackElement = document.getElementById(ctrackID);
    this.cTrackCanvasContext = this.ctrackElement.getContext('2d');
  }

  drawLoop = () => {
    if (window.preventLoop) return;
    window.requestAnimFrame(this.drawLoop);
    this.cTrackCanvasContext.clearRect(0, 0, 1920, 1080);
    let points = this.instance.getCurrentPosition();
    if (points) {
      this.cTrackCanvasContext.beginPath();
      this.cTrackCanvasContext.arc(this.cx, this.cy, this.r, 0, 2 * Math.PI);
      this.cTrackCanvasContext.lineWidth = 5;
      if(this.checkPoint(points[0][0], points[0][1]) && this.checkPoint(points[14][0], points[14][1])){
          this.cTrackCanvasContext.strokeStyle = '#82ff32';
       }
       else{
         this.cTrackCanvasContext.strokeStyle = '#FF3333';
         stopAnimation();
         resetCheckPositionCounter();
       }
      this.cTrackCanvasContext.stroke();
      this.cTrackCanvasContext.lineWidth = 1;
      this.instance.draw(this.ctrackElement);
    } else {
      this.cTrackCanvasContext.beginPath();
      this.cTrackCanvasContext.arc(this.cx, this.cy, this.r, 0, 2 * Math.PI);
      this.cTrackCanvasContext.lineWidth = 5;
      this.cTrackCanvasContext.strokeStyle = '#FF3333';
      this.cTrackCanvasContext.stroke();
      stopAnimation();
      resetCheckPositionCounter();
    }

  }

  checkPoint = (a, b) => {
    var x = this.cx;
    var y = this.cy;
    var r = this.r;
    let point = (a - x) * (a - x) + (b - y) * (b - y);
    r *= r;
    if (point < r) {
      return true;
    }
    return false;
  }

  getVideoDimensions = (video) => {
    var videoRatio = video.videoWidth / video.videoHeight;
    var width = video.offsetWidth;
    var height = video.offsetHeight;

    var elementRatio = width / height;
    if (elementRatio > videoRatio) {
      width = height * videoRatio;
    } else {
      height = width / videoRatio;
    }
    return {
      width,
      height
    };
  }

  adjustDimensions = () => {
    if (!this.videoElement) return;

    this.vidDim = this.getVideoDimensions(this.videoElement);
    this.cx = this.vidDim.width / 2;
    this.cy = this.vidDim.height / 2;
    this.r = this.cy / 1.2;
    let overlay = document.getElementById('overlay');


    overlay.setAttribute("height", this.vidDim.height);
    overlay.setAttribute("width", this.vidDim.width);
  	this.videoElement.setAttribute("width", this.vidDim.width);
  	this.videoElement.setAttribute("height", this.vidDim.height);

    let dimensions = calcDimensions();
    if (dimensions.width < 640 || (dimensions.width >= 576 && dimensions.width <= 992 && dimensions.height >= 280 && dimensions.height <= 576)) {
      overlay.style.left = ((calcDimensions().width - this.vidDim.width) / 2) + 'px';
    }
  }
}
