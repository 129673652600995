import React, {Component} from 'react';
import Introduction from './components/regularscreen/Introduction.jsx';
import Setup from './components/regularscreen/Setup.jsx';
import CalibrationIntro from './components/regularscreen/CalibrationIntro.jsx';
import Stepper from './components/Stepper.jsx';
import Fullscreen from './components/Fullscreen.jsx';

// import Tasks from './components/tasks/Tasks.jsx';
import Uploading from './components/Uploading.jsx';
import Finish from './components/Finish.jsx';
import Restart from './components/Restart.jsx';
import Error from './components/Error.jsx';
import NoID from './components/NoID.jsx';

import fullscreen from './helpers/fullscreen.js';
import {parameters} from './helpers/parameters.js';
import {TimeStats} from './helpers/TimeStats.js';
import './assets/styles/index.scss';
import 'materialize-css/dist/css/materialize.min.css';
import logo from './assets/images/logo-(tertiary-white).png';
import mobileLogo from './assets/images/logo.png';
import api from './api';
import { useTranslation } from 'react-i18next';
import {g} from './helpers/functions.js';
import {steps} from './helpers/steps.js';
import {Provider} from './Context.jsx';
const {t} = useTranslation();

class App extends Component {
  progress = 0;

  constructor(props){
    super(props);
    this.state = {
      views: [],
      steps: [],
      currentStep: 0,
      skipCalibration: true,
      isFullscreen: false,
      isStopped: false,
      isError: false,
      startErrCounter: 0,
      isValid: true
    }
  }

  initTestView = () => {
    let steps = [...this.steps];
    let views = [...this.views];
    let skipCalibration = false;

    if (window.parameters.skipSetup && window.parameters.skipSetup === 'true') {
      views = views.filter(view => view.id !== 'setup');
      steps = steps.filter(step => step.title !== 'Setup');
    }

    if (window.parameters.skipCalibration && window.parameters.skipCalibration === 'true') {
      skipCalibration = true;
      views = views.filter(view => view.id !== 'calibrationIntro');
      steps = steps.filter(step => step.title !== 'Calibration');
    }

    if (window.isFCOnly) {
      steps = steps.filter(step => step.title !== 'Calibration');
      // window.preventAPI = true;
    }

    this.setState({views, steps, skipCalibration});
  }

  next = () => {
    let currentStep = this.state.currentStep + 1;
    if (this.state.views[currentStep].id === 'fullscreen') {
      this.enterFullscreen();
    }
    this.setState({currentStep});
  }

  restart = () => {
    this.setState({currentStep: 0});
    window.isCameraEnabled = false;
  }

  startOver = (isRestarted) => {
    this.setState({isStopped: false});
    g.updateTest('restarted', 1);
    window.isCameraEnabled = false;
  }

  startCalibration = () => {
    g.updateTest('calibrate', 1);
    this.enterFullscreen();
  }

  enterFullscreen = () => {
    this.setState({isFullscreen: true});
  }

  exitFullscreen = async () => {
    fullscreen.off();
    let currentStep = this.state.currentStep + 2;
    if (currentStep >= this.state.views.length)
      currentStep = this.state.views.length - 1;
    await this.setState({currentStep, isFullscreen: false});
  }

  stopTest = () => {
    let formData = new FormData();
    formData.append('uUserID', window.parameters.uUserID);
    api.stopTest(formData)
      .then(response => {
        console.log('response stopTest ', response);
      })
      .catch(err => {
        console.log('error stopTest ', err);
      });
  }

  clearIntervalsAndTimeouts = () => {
    let killId = setTimeout(() => {
      for (let i = killId; i > 0; i--) {
        window.clearTimeout(i);
        window.clearInterval(i);
        if(window.mozCancelAnimationFrame) window.mozCancelAnimationFrame(i);
      }
      this.setState({isFullscreen: false, currentStep: 0});
    }, 60);
  }

  stopCalibration = () => {
    let data = new FormData();
    data.append('uUserID', window.parameters.uUserID);
    data.append('calibrationID', window.parameters.calibrationID);

    return api.stopCalibration(data)
      .then(response => {
        window.calibrationFinished = true;
        this.onAbort();
      })
      .catch(err => {
        this.onAbort(); //??
      });
  }

  onAbort = async() => {
    window.isFullscreen = false;
    window.isCameraEnabled = false;
    window.preventLoop = true;
    await this.clearIntervalsAndTimeouts();
    this.setState({isStopped: true});
    g.updateTest('aborted', 1);
    this.stopTest();
  }

  cancel = () => {
    if (window.project)
      window.location.href = `${process.env.REACT_APP_API_URL}/front/cancelTest?tester_id=${window.parameters.tester_id}`;
  }

  componentDidMount() {
    parameters.saveURLParams();
    this.initTestView();

    fullscreen.onChange(() => {
      if (!document.fullscreenElement && !document.webkitFullscreenElement && !document.mozFullScreenElement && !document.msFullscreenElement && window.isFullscreen && window.isCameraEnabled) {
          if (window.parameters.calibrationID && !window.calibrationFinished) {
            console.log('stop calibration');
            this.stopCalibration();
            window.isCameraEnabled = false;
            return;
          }
        this.onAbort();
      }
    });
}
  getCurrentView = (isFullscreen, isStopped, regularScreen) => {
    if (this.state.isError) {
      return (
        <Error onStartOver={this.onTryAgain} cancel={this.cancel}>
          <p> {t("An error occured. We are sorry for the inconvenience.")}  </p>
          <p> {t("You can start over again or you can exit the platform.")} </p>
        </Error>
      )
    }
    if (!this.state.isValid) {
      return <NoID/>
    }
    return isFullscreen ? <Fullscreen exitFullscreen={this.exitFullscreen} /> : isStopped ? <Restart startOver={this.startOver} cancel={this.cancel}/> : regularScreen;
  }

  onTryAgain = () => {
     this.setState({currentStep: 0, isError: false});
  }

  showError = () => {
    if (this.state.startErrCounter === 0) {
      let startErrCounter = this.state.startErrCounter + 1;
      this.setState({isError: true, startErrCounter});
    } else {
      window.location.href = `${process.env.REACT_APP_API_URL}/front/cancelTest?tester_id=${window.parameters.tester_id}`;
    }
  }

  updateView = isValid => {
    this.setState({isValid})
  }

  views = [
    {id: 'introduction', component: <Introduction next={this.next} onError={() => {this.showError()}}/>},
    {id: 'setup', component: <Setup next={this.next} reset={this.restart} cancel={this.cancel}/>},
    {id: 'calibrationIntro', component: <CalibrationIntro next={this.next} cancel={this.cancel} reset={this.restart} startCalibration={this.startCalibration}/>},
    {id: 'fullscreen', component: <Fullscreen exitFullscreen={this.exitFullscreen} />},
    {id: 'uploading', component: <Uploading next={this.next} showError={this.showError}/>},
    {id: 'finish', component: <Finish />}
  ];

  steps = steps.init();

  render() {
    let {currentStep, isFullscreen, isStopped} = this.state;

    let step = this.state.views.length ? this.state.views[currentStep].component : null;

    let regularScreen = (
      <>
        <div className="logo center-align">
          <img src={logo} alt="EyeSee"/>
        </div>

        <Stepper activeStep={currentStep} steps={this.state.steps} />

        {step}

        <img src={mobileLogo} alt="EyeSee" id="mobile-logo"/>
      </>
    )

    let view = this.getCurrentView(isFullscreen, isStopped, regularScreen);

    return (
        <div className="App">
          <Provider value={{isValid: this.state.isValid, updateView: isValid => this.updateView(isValid)}}>
            {
              view
            }
          </Provider>
        </div>
    );
  }

}
export default App;
