import React from 'react';
import { useTranslation } from 'react-i18next';

export default function StatusMobile(props) {
  const {t} = useTranslation();
  return (
    <div className="camera-status card-panel">
        <section id="statusWait">
          <p>{ t("Please position yourself inside the circle and then continue") }</p>
          <button className="btn btn-small btn-eyesee-linear" onClick={props.startCalibration}> { t("Continue") } </button>
        </section>
    </div>
  )
}
