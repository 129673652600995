import React, {useState, useEffect} from 'react';
import video from '../../assets/icons/video.png';
import emotions from '../../assets/icons/emotions.png';
import sound from '../../assets/icons/sound.png';
import warning from '../../assets/icons/pretty-still.png';
import { useTranslation } from 'react-i18next';

export default function Intro({introslides, taskName, showStimulus}) {
  const {t} = useTranslation();
  let [introslideShown, setIntroslideShown] = useState({content: ''});
  let [btnText, setBtnText] = useState('Next');
  
  useEffect(() => {
    setIntroslideShown(introslides[0]);
  }, [introslides]);

  let updateBtnText = () => {
    let idx = introslides.findIndex(introslide => introslide.id === introslideShown.id);
    let txt = idx < introslides.length - 1 ? 'Next' : 'Start';
    setBtnText(txt);
  }

  let next = () => {
    let idx = introslides.findIndex(introslide => introslide.id === introslideShown.id);
    if (idx === introslides.length - 1)
      showStimulus();
    else
      setIntroslideShown(introslides[idx + 1]);
  }

  useEffect(updateBtnText, [introslideShown]);

  return (
    <div className="task-intro">
      <div className="general-advice section">
        <h5> { t("Proper setup") } </h5>

        <section className="advice-list">
          <div className="advice">
            <img src={video} alt="video"/>
            <p> { t("We will share a video(s).") } </p>
          </div>

          <div className="advice">
            <img src={emotions} alt="emotions"/>
            <p> { t("Feel free to express emotions as you watch.") } </p>
          </div>

          <div className="advice">
            <img src={sound} alt="sound"/>
            <p> { t("You’ll need to hear sounds – please make sure that your speakers are on or your headphones are plugged in.") } </p>
          </div>

          <div className="advice">
            <img src={warning} alt="warning"/>
            <p>  { t("Remember to stay pretty still and be careful to avoid excessive head movements.") } </p>
          </div>
        </section>
      </div>

      <div className="task section">
        <h5 className="task-title">
          <i className="material-icons">help</i>
          <span> { t("Your task") } - {taskName} </span>
        </h5>
        <div dangerouslySetInnerHTML={{__html: introslideShown.content}}></div>
      </div>

      <div className="controls section">
        <button className="btn-floating btn-large btn-eyesee" onClick={next}> { t(btnText) } </button>
      </div>
   </div>
  )
}
