export const videoJSOptions = {
  controls: false,
  width: 640,
  height: 480,
  fluid: false,
  plugins: {
    record: {
      audio: false,
      video: {frameRate: { ideal: 30, max: 30 }, width: 640, height: 480, facingMode: 'user'},
      maxLength: 9999,
      debug: true,
      frameWidth:640,
      frameHeight:480,
      convertEngine: 'ts-ebml',
      autoMuteDevice: true
    }
  }
};

export function setVideoJSDimensions (width, height, videoJSOptions) {
    let options = JSON.parse(JSON.stringify(videoJSOptions));
    options.width = width;
    options.height = height;
    options.plugins.record.frameWidth = width;
    options.plugins.record.frameHeight = height;
    return options;
};

export function calcDimensions() {
  var docHeight = document.documentElement.clientHeight;
  var docWidth = document.documentElement.clientWidth;
  var width = docWidth;
  var height = width / (640/480) // 4/3 aspect ratio

  if (width >= 640) {
    width = 640;
    height = 480;
  }

  if (docWidth >= 576 && docWidth <= 992 && docHeight >= 280 && docHeight <= 576) {
    width = docWidth;
    height = docHeight - 50;
  }

  return {width: width, height: height};
}
