import React, {useEffect} from 'react';
import paths from '../../helpers/paths.js';
import { useTranslation } from 'react-i18next';
import Player from '../../entities/Player.js';
import Video from '../partials/Video.jsx';
import api from '../../api/';
import {videoJSOptions} from '../../data/videoJSOptions.js';
import {g} from '../../helpers/functions.js';
let player = null;
let calibrationPoint = null;

export default function Calibration(props) {
  const {t} = useTranslation();
  const dot = React.createRef();
  const dotText = React.createRef();
  let isDotDisabled = true;
  let dotsClicked = 0;

  let position = 0;

  useEffect(() => {
    let callbacks = {
      onFinishConversion: calibrate
    }
    player = new Player(document.getElementById('videoJS'), videoJSOptions, callbacks);
  //  let dotsClicked = 0;
    startCalibration();
    return () => {
      player.destroy();
    }
  }, []);

  function startCalibration() {
    let params = {
      uUserID: window.parameters.uUserID,
      webCamVideoW: 640,
      webCamVideoH: 480,
      stimulusViewX: 0,
      stimulusViewY: 0,
      stimulusViewW: window.screen.width,
      stimulusViewH: window.screen.height
    }
    let formData = new FormData();
    for (let p in params) {
      formData.append(p, params[p]);
    }

    api.startCalibration(formData)
      .then(response => {
        window.parameters.calibrationID = response.data.calibrationID;
        window.calibrationFinished = false;
        window.stopCalibrationCalled = false;
        window.stopCalibration = false;
        isDotDisabled = false;
      })
      .catch(err => {
        onCalibrationError();
      })
  }

  function calibrate (convertedData) {
    if (window.stopCalibrationCalled) return;
    let formData = new FormData();
    let params = {
      uUserID: window.parameters.uUserID,
      calibrationID: window.parameters.calibrationID,
      pointX: calibrationPoint.x,
      pointY: calibrationPoint.y,
      stimulusViewX: 0,
      stimulusViewY: 0,
      stimulusViewW: window.screen.width,
      stimulusViewH: window.screen.height
    }

    for (let p in params) {
      formData.append(p, params[p]);
    }

    formData.append('webCamVideo', convertedData);

    let isError = false;
    api.calibrate(formData)
      .then(response => {
        console.log('CALIBRATE', window.stopCalibration, response);
      })
      .catch(err => {
        onCalibrationError();
        isError = true;
      })
      .finally(() => {
        if (window.stopCalibration || isError)
           stopCalibration();
      });
  }

  let dotClicked = path => {
    if (isDotDisabled || window.stopCalibrationCalled) return;
    isDotDisabled = true;
    calibrationPoint = {y: path.top, x: path.left};
    player.start();
    dotsClicked++;
    g.updateTest('calibrations', dotsClicked);
  }

  let dotMoved = () => {
    player.stop();
    isDotDisabled = false;
  }

  function onCalibrationEnd (path) {
    dotClicked(path);
    window.setTimeout(() => {
      player.stop();
      isDotDisabled = false;
    }, 3000);
  }

  function stopCalibration() {
    if (window.calibrationFinished || window.stopCalibrationCalled) return;
    let data = new FormData();
    data.append('uUserID', window.parameters.uUserID);
    data.append('calibrationID', window.parameters.calibrationID);

    window.stopCalibrationCalled = true;
    api.stopCalibration(data)
      .then(response => {
        window.calibrationFinished = true;
        props.goToNextView();
        isDotDisabled = false;
      })
      .catch(err => {
        onCalibrationError();
        window.stopCalibrationCalled = false;
      })
      .finally(() => {
        dotsClicked = 0;
      });
  }

  function onCalibrationError() {
    //this.setState({isCalibrationMode: false});
    //this.player.record().stop();
  //  this.forbidLoop = false;
  //  this.isValidated = false;
  //  this.counterPosition=0;
  //  this.dotsClicked=0;
  //  this.player.record().getDevice();
  }

  let startAnimation = e => {
    if (window.stopCalibration || isDotDisabled) return;
    dot.current.style.animationName = 'upsize';
    dot.current.style.animationDuration = '3s';
    dotText.current.style.visibility = 'hidden';
    if (position === paths.length - 1) {
      window.stopCalibration = true;
      onCalibrationEnd(paths[position])
      return;
    }
    dotClicked(paths[position]);
  }

  let cancelAnimation = (animationDuration) => {
    dot.current.style.animationName = 'downsize';
    dot.current.style.animationDuration = animationDuration;
    dotText.current.style.visibility = 'visible';
  }

  let moveDot = () => {
    if (window.stopCalibration) return;
    if (dot.current.style.animationName === 'downsize') return;
    cancelAnimation(paths[position].animationDuration);
    position++;
    if (position === paths.length) return;
    dot.current.style.transition = `all ${paths[position].animationDuration}`;
    dot.current.style.left = paths[position].left + 'px';
    dot.current.style.top = paths[position].top + 'px';
    dotMoved();
  }

  let style = {
    left: '40px',
    top: '40px'
  }

  return (
    <div className="calibration">
      <div id="red-dot"
           ref={dot}
           style={style}
           onClick={startAnimation}
           onAnimationEnd={moveDot} >
        <span ref={dotText}> {t("Click")} </span>
      </div>
      <Video />
    </div>
  )
}
