import React, { Component } from 'react';
import Error from '../Error.jsx';
import Intro from './Intro.jsx';
import Stimuli from './Stimuli.jsx';
import Video from '../partials/Video.jsx';
import Recorder from '../../entities/Player.js';
import { TimeStats } from '../../helpers/TimeStats.js';
import axios from 'axios';
import { videoJSOptions, calcDimensions, setVideoJSDimensions } from '../../data/videoJSOptions.js';
import spinner from '../../assets/spinner.gif';
import { useTranslation } from 'react-i18next';
import { stimulusVideo } from './stimulusVideo.js';
import { g } from '../../helpers/functions.js';
import { eyeseeApi } from '../../api/eyesee-api.js';
const { t } = useTranslation();

export default class Task extends Component {
  isStimulusDownloadedInt = null;
  recorder = null;
  upload = false;

  constructor(props) {
    super(props);
    window.stimuliUploadingCounter = 0;
    window.stimuliUploadedCounter = 0;
    window.totalStimuli = 0;
    this.state = {
      isError: false,
      taskShown: window.tasks[0],
      stimuli: {
        exitEnabled: false,
        type: '',
        images: [],
        videos: [],
        videoBlobs: [],
        index: -1
      }
    }
  };

  onBufferStarts = () => {
    this.recorder.pause();
    window.timeStats.updateStimulus(this.state.taskShown.name, this.state.stimuli.videos[this.state.stimuli.index].name, 'pausedRecording');
  };

  onBufferEnds = () => {
    this.recorder.resume();
    window.timeStats.updateStimulus(this.state.taskShown.name, this.state.stimuli.videos[this.state.stimuli.index].name, 'resumedRecording');
  };

  showStimulus = (index = 0) => {
    /*
      All components are constantly on the screen so that video can be played on "Start" button click.
      Click event is used as a playing trigger, because the autoplay would require 'muted' video property, and playing sound is requirement.
      Visibility is changed manually due to the fact that React's setState is asynchronous.
    */
    let { taskShown } = this.state;
    let stimuli = {
      exitEnabled: taskShown.exitClick,
      images: taskShown.imgs,
      videos: taskShown.videos,
      videoBlobs: taskShown.videoBlobs,
      index,
      type: taskShown.imgs.length ? 'image' : 'video'
    };
    this.setState({ stimuli });
    if (stimuli.type === 'video') {
      stimulusVideo.setCurrentData(taskShown.name, stimuli.videos[index].name);
      stimulusVideo.init(this.startRecording, this.onBufferStarts, this.onBufferEnds);
      if (taskShown.isPreloadVideos)
        stimulusVideo.checkIsVideoDownloaded(taskShown, index);
      else
        stimulusVideo.onVideoSrcAvailable(stimuli.videos[index].path)
    }
    g.updateTest('stimulus', (stimuli.index + 1));
    this.recorder.setRecordAudio(!!taskShown.audio);
  };

  initRecorder = () => {
    let callbacks = {
      onStartRecording: this.onStartRecording,
      onFinishRecord: this.onStopRecording,
      onFinishConversion: this.onFinishConversion,
      onError: this.onError,
      onDeviceError: this.onDeviceError
    }
    let { width, height } = calcDimensions();
    let options = setVideoJSDimensions(width, height, videoJSOptions);
    //  this.setState({videoWidth: width, videoHeight: height})
    options.plugins.record.audio = !!this.state.taskShown.audio;
    this.recorder = new Recorder(document.getElementById('videoJS'), options, callbacks);
  };

  onStartRecording = () => {
    window.timeStats.updateStimulus(this.state.taskShown.name, this.state.stimuli.videos[this.state.stimuli.index].name, 'recordCallback');
  };

  onError = () => {
    window.timeStats.updateStimulus(this.state.taskShown.name, this.state.stimuli.videos[this.state.stimuli.index].name, 'error');
  };

  onDeviceError = () => {
    window.timeStats.updateStimulus(this.state.taskShown.name, this.state.stimuli.videos[this.state.stimuli.index].name, 'deviceError');
  };

  startRecording = () => {
    this.recorder.start();
    window.timeStats.updateStimulus(this.state.taskShown.name, this.state.stimuli.videos[this.state.stimuli.index].name, 'record');
  };

  stopRecording = () => {
    let { stimuli, taskShown } = this.state;
    let uploadStimulus = (arr) => {
      return arr[stimuli.index].upload || arr[stimuli.index].upload == undefined; // backwards compatibility for the projects that were set up before this option is developed
    }
    let stimulusName = stimuli.type === 'video' ? stimuli.videos[stimuli.index].name : stimuli.images[stimuli.index].name;
    this.upload = stimuli.type === 'video' ? uploadStimulus(stimuli.videos) : uploadStimulus(stimuli.images);
    this.fcParams = this.generateFCParams(stimulusName, taskShown.name);
    this.recorder.stop();
    window.timeStats.updateStimulus(taskShown.name, stimulusName, 'stoppedRecording');
  };

  generateFCParams = (stimulusName, taskName) => {
    let formData = new FormData();
    formData.append('taskName', taskName);
    formData.append('stimulusName', stimulusName);
    formData.append('tester_id', window.parameters.tester_id);
    return formData;
  };

  onStopRecording = () => {
    let stimuli = {
      ...this.state.stimuli,
      index: this.state.stimuli.index + 1
    };
    if (this.state.stimuli.index + 1 < this.state.taskShown.imgs.length) {
      this.setState({ stimuli });
      return;
    }
    if (this.state.stimuli.index + 1 < this.state.taskShown.videos.length) {
      this.showStimulus(this.state.stimuli.index + 1);
      return;
    };
    this.showNextTask();
  };

  showNextTask = () => {
    let stimuli = {
      exitEnabled: false,
      type: '',
      images: [],
      videos: [],
      videoBlobs: [],
      index: -1
    };
    let taskCurrIdx = window.tasks.findIndex(task => task.id === this.state.taskShown.id);
    if (taskCurrIdx + 1 < window.tasks.length) {
      window.timeStats.updateTask(window.tasks[taskCurrIdx + 1].name, 'startedTask');
      let taskIntroEl = document.getElementsByClassName('task-intro')[0];
      let stimulusElImg = document.getElementById('stimulusImg');
      let stimulusElVideo = document.getElementById('stimulusVideo');
      g.updateTest('tasks', taskCurrIdx + 2);
      this.setState({ stimuli, taskShown: window.tasks[taskCurrIdx + 1] });
      this.hideElement(stimulusElImg);
      this.hideElement(stimulusElVideo);
      taskIntroEl.style.display = 'flex';
    };
  };

  hideElement = el => {
    if (!el) return;
    el.style.display = 'none';
  };

  onFinishConversion = recording => {
    // check if we are doing eyetracking or facial coding or both and handle API calls accordingly
    // send to api
    if (this.state.isError) return;

    if (this.upload) {
      this.fcParams.append('video', recording);
      let taskName = this.fcParams.get('taskName');
      let stimulusName = this.fcParams.get('stimulusName');
      window.timeStats.updateStimulus(taskName, stimulusName, 'uploadingStarted');
      axios.post(`${process.env.REACT_APP_API_URL}fc/upload`, this.fcParams, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      }).then(response => {
        window.stimuliUploadedCounter++;
        window.timeStats.updateStimulus(taskName, stimulusName, 'uploadingEnded');
        eyeseeApi.updateTest({ tester_id: window.parameters.tester_id, state: 'uploading', value: window.stimuliUploadedCounter, timeStats: JSON.stringify(window.timeStats) });
      });
    } else {
      window.stimuliUploadedCounter++;
    }
    window.stimuliUploadingCounter++;
    if (window.stimuliUploadingCounter === window.totalStimuli) {
      this.recorder.destroy();
      this.props.exitFullscreen();
    }
  };

  calcStimuli = () => {
    let stimuliCount = 0;
    window.tasks.forEach(task => {
      stimuliCount += task.imgs.length || task.videos.length;
    });
    return stimuliCount;
  };

  showVideo = () => {
    this.showStimulus(this.state.stimuli.index + 1);
  };

  reset = () => {
    window.stimuliUploadingCounter = 0;
    window.stimuliUploadedCounter = 0;
    window.totalStimuli = 0;
    this.setState({
      isError: false,
      taskShown: window.tasks[0],
      stimuli: {
        exitEnabled: false,
        type: '',
        images: [],
        videos: [],
        videoBlobs: [],
        index: -1
      }
    });
  };

  render() {
    let { taskShown, stimuli } = this.state;
    return (
      <div className="tasks">
        {
          this.state.isError ?
            <Error onStartOver={this.reset} >
              <p> {t("An error occured. We are sorry for the inconvenience.")}  </p>
              <p> {t("You can start over again or you can exit the platform.")} </p>
            </Error>
            : (
              <>
                <div className="task">
                  <Intro introslides={taskShown.introSlides}
                    taskName={taskShown.name}
                    showStimulus={this.showStimulus} />

                  <img src={spinner} alt="Please, wait" className="spinner" />

                  <Stimuli stimuli={stimuli}
                    taskName={taskShown.name}
                    showVideo={this.showVideo}
                    startRecording={this.startRecording}
                    stopRecording={this.stopRecording} />
                </div>

                <Video videoJsOptions={videoJSOptions} />
              </>
            )
        }
      </div>
    )
  };

  componentDidMount() {
    this.initRecorder();
    g.updateTest('tasks', 1);
    window.timeStats.updateTask(window.tasks[0].name, 'startedTask');
    window.totalStimuli = this.calcStimuli();
  };

  componentWillUnmount() {
    this.recorder.destroy();
  };
}
