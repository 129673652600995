import React from 'react';
import { useTranslation } from 'react-i18next';

export default function Restart(props) {
  const {t} = useTranslation();

  return (
    <div className="restart card-panel">
      <h5>
        <i className="material-icons">warning</i>
        <span> {t("Start over")} </span>
      </h5>

      <p> {t("To complete the test, it is required to stay in the fullscreen mode.")}  </p>
      <p> {t("You can start over again or you can exit the platform.")} </p>

      <div className="controls">
        <button className="btn btn-outlined" onClick={props.cancel}> {t("Cancel test")} </button>
        <button className="btn btn-eyesee-linear" onClick={() => props.startOver(true)}> {t("Start over")} </button>
      </div>
    </div>
  )
}
