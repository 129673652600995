import React, { Component } from 'react';
import Calibration from './Calibration.jsx';
import CameraCheck from './CameraCheck.jsx';

class Conditions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currView: 'cameraCheck',
      views: {
        cameraCheck: <CameraCheck startCalibration={() => {this.startCalibration()}} goToNextView={() => {this.props.goToNextView()}}/>,
        calibration: <Calibration goToNextView={() => {this.props.goToNextView()}}/>
      }
    }
  }

  startCalibration = () => {
    this.setState({currView: 'calibration'});
  }

  render() {
    return (
      <div className="conditions">
        {this.state.views[this.state.currView]}
      </div>
    );
  }

}

export default Conditions;
