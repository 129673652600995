import React from 'react';
import { useTranslation } from 'react-i18next';
import eyeTracking from '../../assets/icons/eye-tracking.png';
import warning from '../../assets/icons/warning.png';

export default function CalibrationIntro(props) {
  const {t} = useTranslation();

  return (
    <div className="calibration guide">
      <div className="title">
        <h5>
          {
            //  t("Calibration adapts the system to your eyes")
            }
        </h5>
      </div>

      <div className="content">
      {
        // <div className="content-item">
        //   <img src={eyeTracking} alt="Watch the red dot that will appear on the screen."/>
        //   <section> { t("Watch the red dot") } <p className="red-dot"></p>  { t("that will appear on the screen.") } </section>
        // </div>
      }

        <div className="content-item">
          <img src={warning} alt="Remember to stay pretty still and be careful to avoid excessive head movements."/>
          <p> { t("Remember to stay pretty still and be careful to avoid excessive head movements.") } </p>
        </div>
      </div>

      <div className="ctas">
        <button className="btn-floating btn-large btn-eyesee-outlined" onClick={props.cancel}>{ t("Cancel") }</button>
        <button className="btn-floating btn-large btn-eyesee" onClick={props.startCalibration}> { t("Position now") }</button>
      </div>
    </div>
  );
}
