import {instance as axios} from './eyesee-config.js';

export const eyeseeApi = {
  startTest(testerId) {
    return axios({
      url: 'front/startTest',
      data: {tester_id: testerId},
      method: 'POST'
    });
  },

  updateTest(data) {
    return axios({
      url: 'front/updateTest',
      data,
      method: 'POST'
    });
  },

  endTest(data) {
    return axios({
      url: 'front/endTest',
      data,
      method: 'POST'
    });
  },

  uploadMedia(data) {
    return axios({
      url: 'fc/upload',
      data,
      method: 'POST',
      headers: {
        'content-type': 'multipart/form-data'
      }
    });
  }

}
