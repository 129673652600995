import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import {i18nconfig} from './i18nconfig.js';
import axios from 'axios';

let params = new URLSearchParams(window.location.search);
let lang = params.get('lang') || 'en';
i18nconfig.resources[lang] = {};
i18n
  .use(initReactI18next)
 .init(i18nconfig);

axios.get(`https://data.eyesee-platform.com/languages/${lang}.json`)
  .then(response => {
    let translation = response.data;
    i18nconfig.resources[lang].translations = translation;
    i18n.changeLanguage(lang);
  });

ReactDOM.render(
    <App />,
  document.getElementById('root'));


serviceWorker.unregister();
