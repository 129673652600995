import React from 'react';
import { useTranslation } from 'react-i18next';

import lightsource from '../../../assets/icons/light-source-behind.gif';

export default function Lightsource(props) {
  const {t} = useTranslation();

  return (
    <div className="content-dont lightsource">
      <img src={lightsource} alt="lightsource"/>

      <div className="content-instructions">
        <p> {t("Avoid light sources directly behind you")} </p>
      </div>
    </div>
  );
}
