import React from 'react';
import glasses from '../../../assets/icons/glasses.gif';
import { useTranslation } from 'react-i18next';

export default function Glasses(props) {
  const {t} = useTranslation();
  return (
    <div className="content-dont">
      <img src={glasses} alt=""/>

      <div className="content-instructions">

        <p> { t("We'd prefer if you remove your glasses while doing the interview.") }</p>
        <br />
        <p>{ t("Please take a moment to take off your glasses and perhaps insert contact lenses.") }</p>
      </div>
    </div>
  );
}
