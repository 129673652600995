import {
  instance as axios
} from './config.js';

const api = {
  startTest(data) {
    let formData = new FormData();
    for (let key in data) {
      formData.append(key, data[key]);
    }
    if (window.preventAPI) return Promise.reject('no api calls');
    return axios.post('startTest', formData);
  },

  stopTest(data) {
    if (window.preventAPI) return Promise.reject('no api calls');
    return axios.post('stopTest', data);
  },

  checkConditions(data, cancelToken) {
    if (window.preventAPI) return Promise.reject('no api calls');
    return axios.post('checkConditions', data);
  },

  startCalibration(data) {
    if (window.preventAPI) return Promise.reject('no api calls');
    return axios.post('startCalibration', data);
  },

  calibrate(data) {
    if (window.preventAPI) return Promise.reject('no api calls');
    return axios.post('calibrate', data);
  },

  stopCalibration(data) {
    if (window.preventAPI) return Promise.reject('no api calls');
    return axios.post('stopCalibration', data);
  },

  analyzeEyeTracking(data, idx, task) {
    if (window.preventAPI) return Promise.reject('no api calls');
    return axios.post('analyzeEyeTracking', data, {
      onUploadProgress: progress => {
        console.log('test  ', progress.loaded);
        window.uploading[idx].progress = progress.loaded;
      },
      headers: {
        'content-type': 'multipart/form-data'
      }
    });
  }
}

export default api;
