import React from 'react';

export default function Video(props) {
  return (
    <div className="video">
      <div data-vjs-player>
        <video muted="muted" id="videoJS" className="video-js vjs-default-skin" width="640" height="480"></video>
      </div>
    </div>
  );
}
