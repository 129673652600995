import moment from 'moment';

export class TimeStats {
  constructor() {
    this.timeStats = {
      startedTest: ''
    };
  };

  get = (key) => {
    return this.timeStats[key];
  };

  set = (key, val) => {
    this.timeStats[key] = val;
  };

  update = arrKey => {
    this.timeStats[arrKey].push(TimeStats.getTimestamp());
  };

  static getTimestamp = () => {
    return moment().format('DD.MM.YYYY. HH:mm:ss:ms');
  };

  initializeTasksStats = tasks => {
    tasks.forEach(task => this.initTaskStats(task))
  };

  initTaskStats = task => {
    let name = task.name + '-task';
    let stimuliStats = this.initializeStimuliStats(task);
    this.timeStats[name] = {
      startedTask: '',
      stoppedTask: '',
      ...stimuliStats
    };
  };

  initStimulusStats = () => {
    return {
      downloadStarted: [],
      downloadEnded: [],
      intervalOK: [],
      play: [],
      playCallback: [],
      record: [],
      recordCallback: [],      
      stoppedRecording: [],
      waiting: [],
      pausedRecording: [],
      playing: [],
      resumedRecording: [],
      uploadingStarted: [],
      uploadingEnded: [],
      // bufferStarted: [],
      // bufferEnded: [],
      ended: [],
      error: [],
      deviceError: []
    }
  }

  initializeStimuliStats = task => {
    let stimuli = {};
    if (task.imgs.length) {
      task.imgs.forEach(img => stimuli[`${img.name}-stimulus`] = this.initStimulusStats());
      return stimuli;
    }
    task.videos.forEach(video => stimuli[`${video.name}-stimulus`] = this.initStimulusStats());
    return stimuli;
  };

  getTask = taskName => {
    return this.timeStats[`${taskName}-task`];
  };

  getStimulus = (taskName, stimulusName) => {
    return (this.getTask(taskName))[`${stimulusName}-stimulus`];
  };

  updateTask = (taskName, key) => {
    let task = this.getTask(taskName);
    task[key] = TimeStats.getTimestamp();
  };

  updateStimulus = (taskName, stimulusName, key) => {
    let stimulus = this.getStimulus(taskName, stimulusName);
    if (!stimulus || !stimulus[key]) return;
    stimulus[key].push(TimeStats.getTimestamp());
  };
}
