import 'video.js/dist/video-js.css';
import videojs from 'video.js';
import 'webrtc-adapter';
// eslint-disable-next-line
import RecordRTC from 'recordrtc';
// eslint-disable-next-line
import Record from 'videojs-record/dist/videojs.record.js';
import 'videojs-record/dist/plugins/videojs.record.ts-ebml.js';

export default class Player {
  player = null;

  constructor(node, options, callbacks) {
    this.node = node;
    this.options = options;
    this.emit = callbacks;

    this.player = videojs(node, options, () => {
      let videoDimensions = this.getVideoDimensions(node);
      node.setAttribute("width", videoDimensions.width);
      node.setAttribute("height", videoDimensions.height);
    });

    navigator.mediaDevices.getUserMedia({ video: true, audio: window.isRecordingAudio })
      .then(stream => this.player.record().getDevice())
      .catch(err => {
        if (this.emit.onCameraBlocked)
          this.emit.onCameraBlocked();
      })

    this.player.on('deviceReady', () => {
      if (this.emit.onDeviceReady)
        this.emit.onDeviceReady();
    });

    this.player.on('startRecord', () => {
      if (this.emit.onStartRecording)
        this.emit.onStartRecording();
    });

    this.player.on('finishRecord', () => {
      if (this.emit.onFinishRecord)
        this.emit.onFinishRecord(this.player.recordedData);
        if (this.emit.onFinishConversion)
          this.emit.onFinishConversion(this.player.recordedData);
    });

    this.player.on('finishConvert', () => {
      // if (this.emit.onFinishConversion)
      //   this.emit.onFinishConversion(this.player.convertedData);
    });

    this.player.on('error', (element, error) => {
      if (this.emit.onError)
        this.emit.onError();
      console.warn(error);
    });

    this.player.on('deviceError', () => {
      if (this.emit.onDeviceError)
        this.emit.onDeviceError();
      this.player.reset();
    });
  }

  start = () => {
    if (!this.player.record()) {
      this.player.reset();
    }
    this.player.record().start();
  }

  reset = () => {
    this.player.record().reset();
  }

  setRecordAudio = recordAudio => {
    this.player.record().loadOptions({
      audio: recordAudio
    });
    this.player.record().getDevice();
  }

  stop = () => {
    this.player.record().stop();
  }

  pause = () => {
    this.player.record().pause();
  }

  resume = () => {
    this.player.record().resume();
  }

  destroy = () => {
    this.player.dispose();
  }

  getRecordedData = () => {
    return this.player.recordedData;
  }

  getConvertedData = () => {
    return this.player.convertedData;
  }

  getVideoDimensions = video => {
    var videoRatio = video.videoWidth / video.videoHeight;
    var width = video.offsetWidth, height = video.offsetHeight;
    var elementRatio = width / height;
    if (elementRatio > videoRatio) {
      width = height * videoRatio;
    } else {
      height = width / videoRatio;
    }
    return {
      width: width,
      height: height
    };
  }
}
