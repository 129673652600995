import {eyeseeApi} from '../api/eyesee-api.js';

export const g = {
  randomizeArr(arr) {
    let arrRandomized = [];
    while (arr.length) {
      let i = Math.floor(Math.random() * arr.length);
      let el = arr[i];
      arrRandomized.push(el);
      arr.splice(i, 1);
    }
    return arrRandomized;
  },

  deepCopy(entity) {
    return JSON.parse(JSON.stringify(entity));
  },

  updateTest (state, value) {
    eyeseeApi.updateTest({tester_id: window.parameters.tester_id, state, value});
  },

  endTest () {
    eyeseeApi.endTest({tester_id: window.parameters.tester_id});
  }
}
